import React from "react"
import Select, { components } from "react-select"
import styles from "./select.module.scss"

const { Option } = components

const IconOption = props => (
  <Option {...props}>
    <b>{props.data.label}</b>
    <p>{props.data.description}</p>
  </Option>
)

export default ({ value, setValue, values, placeholder }) => {
  const options = values.map(value => ({
    value: value.value,
    label: value.value,
    description: value.description,
  }))

  return (
    <Select
      className={styles.select}
      isClearable
      placeholder={placeholder}
      value={value && { value, label: value }}
      onChange={op => setValue(op && op.value)}
      options={options}
      components={{ Option: IconOption }}
    />
  )
}
