import React from "react"
import Layout from "../components/layout"
import Calculator from "../components/calculator"
import styles from "./order-house-construction.module.scss"
import { BottomText } from "../components/text"

const OrderHouseConstructionPage = ({ data, location }) => {
  const cities = []

  const title = "Побудувати будинок під ключ " + new Date().getFullYear()
  const description = `${cities.map(c => c.name).join(", ")}`

  const pageText = data?.contentfulText

  return (
    <Layout
      contentClassName="layout-content"
      title={title}
      description={description}
      location={location}
    >
      <h1>Будинок під ключ</h1>

      <p className={styles.bottomText}>
        Побудувати комфортний, новий, цегляний будинок поблизу Києва від{" "}
        <b>
          600$/ м<sup>2</sup>
        </b>
        .{" "}
        {cities
          .sort((a, b) => b.count - a.count)
          .map(c => c.name)
          .join(", ")}{" "}
        та ін.
      </p>
      <div className={styles.calculatorWrapper}>
        <Calculator />
      </div>
      <BottomText textJson={pageText?.text.json} />
    </Layout>
  )
}

export const query = graphql`
  query($pageId: String!) {
    contentfulText(page: { eq: $pageId }) {
      text {
        json
      }
      showTop
      page
    }
  }
`

export default OrderHouseConstructionPage
