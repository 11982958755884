import React, { useState } from "react"
import RangeSlider from "./range-slider"
import Select from "./select"
import OrderHouseConstructionForm from "../form/order-house-construction"
import { formatPrice } from "../../utils/format"
import styles from "./calculator.module.scss"
import finishedImg from "./images/finished.jpg"
import finishedHalfImg from "./images/finished_half.jpg"

const BUILD_M2_PRICE = [600, 800]
const PLOT_M2_PRICE = [0, 10000]
const FINISHING_M2_PRICE = [150, 250]
const FINISHING = {
  prepared: {
    value: "Під чистове оздоблення",
    description: `
    стяжка підлоги; 
машинна штукатурка стін; 
паркан по всьому периметру;
інженерні комунікації;
металопластикові вікна; вхідні двері`,
    image: finishedHalfImg,
  },
  completed: {
    value: "З ремонтом",
    description: `
    підлога(ламінат, плитка, плінтус); 
стіни(декоративна штукатурка, шпалери, плитка);
натяжні стелі;
міжкімнатні двері;
сантехніка; меблі`,
    image: finishedImg,
  },
}

const calcPrice = ({ houseArea, plotArea, finishing }) => {
  const buildingPrice = BUILD_M2_PRICE.map(value => houseArea * value)
  const plotPrice = PLOT_M2_PRICE.map(value => plotArea * value)
  const finishingPrice =
    finishing === FINISHING.completed.value
      ? FINISHING_M2_PRICE.map(value => houseArea * value)
      : 0
  const total = [
    plotPrice[0] + buildingPrice[0] + (finishingPrice[0] || 0),
    plotPrice[1] + buildingPrice[1] + (finishingPrice[1] || 0),
  ]

  return {
    buildingPrice,
    plotPrice,
    finishingPrice,
    price: total,
    priceM2: total.map(value => Math.round(value / houseArea)),
  }
}

const ResultItem = ({ label, total }) => (
  <div className={styles.resultListItem}>
    <div>{label}</div>
    <div>
      <span>{total === 0 ? 0 : total.map(formatPrice).join("-")} $</span>
    </div>
  </div>
)

export default () => {
  const [plotArea, setPlotArea] = useState(8)
  const [houseArea, setHouseArea] = useState(180)
  const [finishing, setFinishing] = useState(FINISHING.prepared.value)

  const price = calcPrice({ houseArea, plotArea, finishing })

  return (
    <div style={{ maxWidth: "100%" }}>
      <h2>Калькулятор монолітного будинку під ключ</h2>
      <div className={styles.container}>
        <div className={styles.selectors}>
          <Select
            placeholder="Фінішна обробка"
            value={finishing}
            setValue={setFinishing}
            values={Object.values(FINISHING)}
          />
        </div>
        <RangeSlider
          value={houseArea}
          setValue={setHouseArea}
          minValue={90}
          maxValue={500}
          label="Площа будинку"
          unit="м2"
        />

        <RangeSlider
          value={plotArea}
          setValue={setPlotArea}
          minValue={4}
          maxValue={20}
          label="Площа ділянки"
          unit="сот."
        />
        <div className={styles.result}>
          <div className={styles.resultList}>
            <ResultItem label="Ділянка" total={price.plotPrice} />
            <ResultItem label="Будівництво" total={price.buildingPrice} />
            <ResultItem label="Ремонт" total={price.finishingPrice} />
          </div>
          <div className={styles.sumResult}>
            <div>від {price.price.map(formatPrice).join(" до ")} $</div>
            <OrderHouseConstructionForm
              data={{
                plotArea,
                finishing,
                houseArea,
                price,
              }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
