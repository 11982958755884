import React from "react"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

const descriptionOptions = {
  renderNode: {
    "embedded-asset-block": node => {
      const url = node.data.target.fields.file["uk-UA"].url
      const alt = node.data.target.fields.title["uk-UA"]

      return <img src={url} alt={alt} />
    },
  },
}

const BottomText = ({ textJson }) => {
  return textJson ? (
    <div className="description-text" style={{ padding: "0 15px" }}>
      {documentToReactComponents(textJson, descriptionOptions)}
    </div>
  ) : null
}

export default BottomText
