import React from "react"
import styles from "./range-slider.module.scss"

export default ({ value, setValue, maxValue, minValue, label, unit }) => {
  return (
    <div style={{ width: "100%" }}>
      <div className={styles.rangeSlider}>
        <span className={styles.rsLabel}>
          {label}: {value} {unit}
        </span>
        <input
          className={styles.rsRange}
          type="range"
          value={value}
          onChange={e => setValue(e.target.value)}
          min={minValue}
          max={maxValue}
        />
      </div>

      <div className={styles.boxMinmax}>
        <span>{minValue}</span>
        <span>{maxValue}</span>
      </div>
    </div>
  )
}
