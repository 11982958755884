import React, { useCallback, useState } from "react"

import Modal from "react-overlays/Modal"

import { getIcon, sendEventToGA4, sendLead, wait } from "../../utils/index"
import { Icon } from "../../components/icon"
import { SuccessForm } from "../modal"

import styles from "./order-house-construction.module.scss"
import { HourGlassLoader } from "../loader"

function OrderHouseConstructionForm({ data }) {
  const [show, setShow] = useState(false)
  const [showSuccess, setShowSuccess] = useState(false)

  const renderBackdrop = props => <div className="modal-backdrop" {...props} />

  const [result, setResult] = useState()
  const [name, setName] = useState("")
  const [phone, setPhone] = useState("")

  const [isSending, setIsSending] = useState(false)

  const sendRequest = useCallback(
    async params => {
      if (isSending) {
        return
      }

      setIsSending(true)

      const result = await sendLead({
        name: params.name,
        phone: params.phone,
        message: `Будинок ${data.houseArea}м2 на ділянці ${
          data.plotArea
        }сот. - ${data.finishing}

        Ціна будівництва: від ${data.price.buildingPrice.join(" до ")} $
        Ціна ділянки: від ${data.price.plotPrice.join(" до ")} $
        Ціна ремонту: від ${
          data.price.finishingPrice ? data.price.finishingPrice.join(" до ") : 0
        } $
        
        Загальна ціна: ${data.price.price.join(" до ")} $
        Загальна ціна за м2: ${data.price.priceM2.join(" до ")} $
        `,
        form: params.title,
        image: params.image ? "https:" + params.image : null,
      })

      await wait(1.5)

      setResult(result)

      if (result) {
        setShow(false)
        setShowSuccess(true)

        sendEventToGA4({
          action: "send_form",
          category: "form",
          label: "Order House Constructions Form",
        })
      }

      setIsSending(false)
    },
    [isSending, data]
  )

  const sendForm = () =>
    phone
      ? sendRequest({
          name,
          phone,
          title: "Замовлення будинку під ключ",
        })
      : setResult(false)

  return (
    <div className={styles.formInvoker}>
      <button
        onClick={() => setShow(true)}
        style={{ width: "100%" }}
        type="button"
      >
        Відправити дані менеджеру
      </button>
      <Modal
        show={show}
        onHide={() => setShow(false)}
        aria-labelledby="modal-label"
        renderBackdrop={renderBackdrop}
        className={styles.modal}
      >
        <div className={styles.formWrapper}>
          <div className={styles.close}>
            <h3>
              Будинок {data.houseArea} м<sup>2</sup> на ділянці {data.plotArea}
              &nbsp;сот. - {data.finishing}
            </h3>
            <button
              onClick={() => setShow(false)}
              type="button"
              aria-label="закрити"
            >
              <Icon name={"закрити"} icon={getIcon("close")} />
            </button>
          </div>
          <div>
            Ціна будівництва:{" "}
            <b>від {data.price.buildingPrice.join(" до ")} $</b>
          </div>
          <div>
            Ціна ділянки: <b>від {data.price.plotPrice.join(" до ")} $</b>
          </div>
          <div>
            Ціна ремонту:{" "}
            <b>
              від{" "}
              {data.price.finishingPrice
                ? data.price.finishingPrice.join(" до ")
                : 0}{" "}
              $
            </b>
          </div>
          <div>
            Загальна ціна: <b>{data.price.price.join(" до ")} $</b>
          </div>
          <div>
            Загальна ціна за м2: <b>{data.price.priceM2.join(" до ")} $</b>
          </div>
          <br />
          <form className={styles.form}>
            <input
              placeholder="Ваше ім'я"
              type="text"
              name="name"
              value={name}
              onChange={e => setName(e.target.value)}
            />
            <input
              placeholder="Ваш телефон"
              type="tel"
              name="phone"
              value={phone}
              onChange={e => setPhone(e.target.value)}
            />
            <div className={styles.sendResultWrapper}>
              {[true, false].includes(result) ? (
                <span>
                  {result === false &&
                    "Помилка! Перевірте дані та спробуйте ще раз."}
                </span>
              ) : null}
            </div>

            <div className={styles.sendButtonWrapper}>
              {isSending ? (
                <HourGlassLoader />
              ) : (
                <button
                  className={styles.primaryBtn}
                  type="button"
                  onClick={sendForm}
                >
                  Відправити
                </button>
              )}
            </div>
          </form>
        </div>
      </Modal>
      <SuccessForm show={showSuccess} setShow={setShowSuccess} name={name} />
    </div>
  )
}

export default OrderHouseConstructionForm
